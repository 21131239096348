import axios from "axios";
import { Skeleton } from "@mui/material";

// testing gitignore
//staging to aws-staging

// export const baseUrl = "https://api.dopamine.fit";
// export const baseUrl = "https://stag.dopamine.fit/";
export const baseUrl = "https://stag.dopamine.fit";
// export const baseUrl = "http://localhost:4444";
// export const baseUrl = "https://dopamine-stag-api.herokuapp.com";
export const baseApiUrl = baseUrl + "/api/";

export const apiCall = axios.create({
  baseURL: baseApiUrl,
  timeout: 15000,
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      headers["Content-Type"] = "application/json";
      headers["Authorization"] = `Bearer ${
        fetchData("user_data")?.token || ""
      }`;
      return JSON.stringify(data);
    },
  ],
});

// apiCall.interceptors.request.use((config) => {
// config.headers.Authorization = `Bearer ${
//   fetchData("user_data")?.token || ""
// }`;
//   return config;
// });

export const storeData = (type, value) => {
  localStorage.setItem(type, JSON.stringify(value));
};

export const fetchData = (type) => {
  if (localStorage.getItem(type)) return JSON.parse(localStorage.getItem(type));
  return "";
};

export const preloadImage = (url = "") => {
  const newImage = new Image();
  newImage.src = url;
  return;
};

export const LoadingWrapper = ({ loading = false, style = {}, children }) =>
  loading ? (
    <Skeleton style={{ backgroundColor: "#666", ...style }}>
      {children}
    </Skeleton>
  ) : (
    children
  );
